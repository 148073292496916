body {
  margin: 0;
  font-family: 'Cairo', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.card {
    display: flex !important;
    flex-direction: column !important;
    cursor: pointer !important;
  
  }
.card span {
      margin-top: 0.75rem !important;

      
    }
.card span  h3 {
        font-size: 1.25rem !important;
      }

.card  img {
      border-radius: 1rem !important;
      width: 100% !important;
      transition: all 0.2s !important;
 }

    .card:hover img {
      transform: scale(0.95) !important;
    }

    .card .img {
      position: relative !important;
  
      
    }

  .card   .img > div:first-child {
        position: absolute !important;
        overflow: visible !important;
        width: 100%;
      }

       .card .img > div {
        width: 100% !important;
      }

      .card   .shadow {
      position: absolute !important;
      filter: blur(1rem) brightness(80%) !important;
      transform: translateY(0.5rem) scaleX(0.9) !important;
      z-index: -1 !important;
      opacity: 0.6 !important;
    }