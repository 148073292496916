body{
    background-color: #f7f7fc;
    --bs-gradient: linear-gradient(to right, #766df4 0%, #766df4 30%, #6a9bf4 100%);
    --bs-secondary: #f7f7fc;
  
}

/* -- user dashboard -- */
.bg-light{
    background-color: #fff !important;
}
.bg-gradient {
    background-image: var(--bs-gradient) !important;
    height: 480px;
    position: relative !important;
        margin-top: -60px !important;
    z-index: -1;
}
.fs-lg{
    font-size: 1.125rem !important;
}
.me-2 {
    margin-left: .5rem !important;
}
.shape.bg-secondary {
    color: #f7f7fc !important;
}
.shape.bg-light, .shape.bg-body, .shape.bg-primary, .shape.bg-secondary, .shape.bg-dark, .shape.bg-darker{
    background-color: transparent !important;
}
.shape-slant{
    padding-bottom: 8.7%;
}
.shape-bottom{
    bottom: 0;
    margin-bottom: -1px;
}
.shape{
    position: absolute;
    width: 100%;
    overflow: hidden;
    z-index: 2;
}
.shape svg{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
}
svg {
    max-width: 100%;
}
.rounded-3{
    border-radius: 1rem !important;   
}
.zindex-5{
    z-index: 5 !important;

}

        /* -sidebar user dashboard- */
        .bg-secondary{
            background-color: #f7f7fc !important;
        }
        .fw-semibold {
            font-weight: 600 !important;
        }
        .fs-sm{
            font-size: .875rem !important;
        
        }
        .nav-link-style.active {
            color: #766df4;
            pointer-events: none;
            cursor: default;
        }
        .ms-auto {
            margin-right: auto !important;
        }
        .nav-link-style{
            display: inline-block;
            transition: color 0.25s ease-in-out,background-color 0.25s ease-in-out,box-shadow 0.25s ease-in-out,border-color 0.25s ease-in-out;
            color: #5a5b75;
            font-weight: 500;
            text-decoration: none;
            cursor: pointer;
        }

        @media (min-width: 992px){
            .d-lg-none {
                display: none !important;
                }
        }
        .collapse:not(.show) {
            display: none;
        }
       
        .btn-primary {
            color: #fff !important;

            background-color: #766df4 !important;
            border-color: #766df4 !important;
            box-shadow: unset !important;
        }
        .btn {
            display: inline-block;
            font-weight: 500;
            line-height: 1.5;
            color: #737491;
            text-align: center;
            white-space: nowrap;
            vertical-align: middle;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            user-select: none;
            background-color: transparent;
            border: 1px solid transparent;
            padding: .5625rem 1.25rem;
            font-size: 1rem;
            border-radius: .75rem;
            transition: color 0.25s ease-in-out,background-color 0.25s ease-in-out,border-color 0.25s ease-in-out;
        }
/* --about me-- */

.px-0{
    padding-right: 0 !important;
    padding-left: 0 !important;
}
.form-label{
  margin-bottom: .375rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #4a4b65;  
}
.form-control{
        display: block;
    width: 100%;
    padding: .5625rem 1.125rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #737491;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dfdfeb;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: .75rem;
    box-shadow: 0 0 0 0 transparent;
    transition: border-color .2s ease-in-out,box-shadow .2s ease-in-out;
}
.btn-light {
    border-color: transparent;
    background-color: #fff;
    color: #5a5b75;
}
.btn-sm, .btn-group-sm>.btn {
    padding: .425rem 1rem;
    font-size: 0.875rem;
    border-radius: .5rem;
}
/* .card *, .flex-column * {
    min-height: .01px;
} */
@media (min-width: 500px){
.text-sm-start {
    text-align: right !important;
}
.ps-sm-3 {
    padding-right: 1rem !important;
}
}

/* --Portfolios add-- */

.c_review {
    margin-bottom: 0
}

.c_review li {
    margin-bottom: 16px;
    padding-bottom: 13px;
    border-bottom: 1px solid #e8e8e8
}

.c_review li:last-child {
    margin: 0;
    border: none
}

.c_review .avatar {
    float: left;
    width: 80px
}

.c_review .comment-action {
    float: left;
    width: calc(100% - 80px)
}

.c_review .comment-action .c_name {
    margin: 0
}

.c_review .comment-action p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 95%;
    display: block
}

.product_item:hover .cp_img {
    top: -40px
}

.product_item:hover .cp_img img {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22)
}

.product_item:hover .cp_img .hover {
    display: block
}

.product_item .cp_img {
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translate(-50%);
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    -moz-transform: translate(-50%);
    -o-transform: translate(-50%);
    -khtml-transform: translate(-50%);
    width: 100%;
    padding: 15px;
    transition: all 0.2s ease-in-out
}

.product_item .cp_img img {
    transition: all 0.2s ease-in-out;
    border-radius: 30px
}

.product_item .cp_img .hover {
    display: none;
    text-align: center;
    margin-top: 10px
}

.product_item .product_details {
    padding-top: 70%;
    text-align: center
}

.product_item .product_details h5 {
    margin-bottom: 5px
}

.product_item .product_details h5 a {
    font-size: 16px;
    color: #444
}

.product_item .product_details h5 a:hover {
    text-decoration: none
}

.product_item .product_details .product_price {
    margin: 0
}

.product_item .product_details .product_price li {
    display: inline-block;
    padding: 0 10px
}

.product_item .product_details .product_price .new_price {
    font-weight: 600;
    color: #ff4136
}

.product_item_list table tr td {
    vertical-align: middle
}

.product_item_list table tr td h5 {
    font-size: 15px;
    margin: 0
}

.product_item_list table tr td .btn {
    box-shadow: none !important
}

.product-order-list table tr th:last-child {
    width: 145px
}

.preview {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
}

.preview .preview-pic {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1
}

.preview .preview-thumbnail.nav-tabs {
    margin-top: 15px;
    font-size: 0
}

.preview .preview-thumbnail.nav-tabs li {
    width: 20%;
    display: inline-block
}

.preview .preview-thumbnail.nav-tabs li nav-link img {
    max-width: 100%;
    display: block
}

.preview .preview-thumbnail.nav-tabs li a {
    padding: 0;
    margin: 2px;
    border-radius: 0 !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important
}

.preview .preview-thumbnail.nav-tabs li:last-of-type {
    margin-right: 0
}

.preview .tab-content {
    overflow: hidden
}

.preview .tab-content img {
    width: 100%;
    -webkit-animation-name: opacity;
    animation-name: opacity;
    -webkit-animation-duration: .3s;
    animation-duration: .3s
}

.details {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
}

.details .rating .stars {
    display: inline-block
}

.details .sizes .size {
    margin-right: 10px
}

.details .sizes .size:first-of-type {
    margin-left: 40px
}

.details .colors .color {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
    height: 2em;
    width: 2em;
    border-radius: 2px
}

.details .colors .color:first-of-type {
    margin-left: 20px
}

.details .colors .not-available {
    text-align: center;
    line-height: 2em
}

.details .colors .not-available:before {
    font-family: Material-Design-Iconic-Font;
    content: "\f136";
    color: #fff
}

@media screen and (max-width: 996px) {
    .preview {
        margin-bottom: 20px
    }
}

@-webkit-keyframes opacity {
    0% {
        opacity: 0;
        -webkit-transform: scale(3);
        transform: scale(3)
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes opacity {
    0% {
        opacity: 0;
        -webkit-transform: scale(3);
        transform: scale(3)
    }
    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

.cart-page .cart-table tr th:last-child {
    width: 145px
}

.cart-table .quantity-grp {
    width: 120px
}

.cart-table .quantity-grp .input-group {
    margin-bottom: 0
}

.cart-table .quantity-grp .input-group-addon {
    padding: 0 !important;
    text-align: center;
    background-color: #1ab1e3
}

.cart-table .quantity-grp .input-group-addon a {
    display: block;
    padding: 8px 10px 10px;
    color: #fff
}

.cart-table .quantity-grp .input-group-addon a i {
    vertical-align: middle
}

.cart-table .quantity-grp .form-control {
    background-color: #fff
}

.cart-table .quantity-grp .form-control+.input-group-addon {
    background-color: #1ab1e3
    /* 0b1133 */
}

.ec-checkout .wizard .content .form-group .btn-group.bootstrap-select.form-control {
    padding: 0
}

.ec-checkout .wizard .content .form-group .btn-group.bootstrap-select.form-control .btn-round.btn-simple {
    padding-top: 12px;
    padding-bottom: 12px
}

/* .ec-checkout .wizard .content ul.card-type {
    font-size: 0
}

.ec-checkout .wizard .content ul.card-type li {
    display: inline-block;
    margin-right: 10px
} */

/* .card {
    background: #fff;
    margin-bottom: 30px;
    transition: .5s;
    border: 1;
    border-radius: 30px !important;
    position: relative;
    width: 100%;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.1);
}

.card .body {
    font-size: 14px;
    color: #424242;
    padding: 20px;
    font-weight: 400;
    cursor: pointer;
} */
.icon_plus{
        font-size: 40px;
    text-align-last: center;
    size: 40px;
}

/* --- Cards --- */

.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 100px;
  height: 50px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
}

.react-switch-label .react-switch-button {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 45px;
  height: 45px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 60px;
}